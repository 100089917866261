import axios from 'axios';

const baseUrl = 'https://api.pspp-integrated.com/api'

export async function apiGet(url) {
    const res = await axios.get(baseUrl + url)
    return res.data;
}

export const apiPost = (url, data, config) => {
    if (!config) {
        config = {}
    }
    config.headers = { 'content-type': 'application/x-www-form-urlencoded' }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    })

    return axios.post(baseUrl + url, formData, config)
        .then(response => {
            return response.data;
        });
}
