import { useEffect, useState } from 'react'
import moment from 'moment'

import { apiGet } from '../../api/Api'

import back from './../../icons/back.svg';

import './../Home/Home.scss'

const RecentUpdate = ({ user }) => {
    const [sekolah, setSekolah] = useState([]);

    useEffect(() => {
        apiGet('/v1/marketing/user/getKuncian')
            .then((r) => {
                setSekolah(r.data);
            })
    }, []);

    return (
        <div className="Home Sekolah">
            <div className="navbar">
                <span onClick={() => {
                    window.history.back(-1);
                }}>
                    <img src={back} height="25" />
                </span>

                <h1 id='nav-title'>Kuncian Sekolah</h1>
            </div>

            <div className="card">
                {
                    sekolah.length <= 0 && (
                        <p style={{ textAlign: 'center', padding: '20px', fontSize: '14px' }}>
                            Tidak ada data
                        </p>
                    )
                }

                <ul className='kuncian'>
                    {
                        sekolah.map((item, index) => (
                            <li key={index}>
                                <div>
                                    <p>{item.sekolah}</p>
                                    <p style={{ fontSize: '14px', color: '#555', fontWeight: '300' }}>({item.marketing})</p>
                                    <span>{item.kota_sekolah + ", " + item.provinsi_sekolah}</span>
                                    <table>
                                        <tr>
                                            <td>Tgl Kunjungan</td>
                                            <td>:</td>
                                            <td>{item.tgl_kunjungan && item.tgl_kunjungan != '0000-00-00' ? moment(item.tgl_kunjungan).format('DD-MM-YYYY') : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Tgl Presentasi</td>
                                            <td>:</td>
                                            <td>{item.tgl_presentasi && item.tgl_presentasi != '0000-00-00' ? moment(item.tgl_presentasi).format('DD-MM-YYYY') : '-'}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <p>Tgl berlaku:</p>
                                    <span>{moment(item.tgl_mulai).format('DD/MM/YYYY')} - {moment(item.tgl_berakhir).format('DD/MM/YYYY')}</span>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default RecentUpdate;