import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.scss';

import { apiGet } from "./api/Api"

import Login from './pages/Login/Login';

import Home from './pages/Home/Home';
import Siswa from './pages/Siswa/Siswa';
import SiswaDetail from './pages/Siswa/SiswaDetail';
import Profil from './pages/Profil/Profil';
import RecentUpdate from './pages/RecentUpdate/RecentUpdate';
import Sekolah from './pages/Sekolah/Sekolah';

function App() {

  const [daftarAngkatan, setDaftarAngkatan] = useState([]);

  useEffect(() => {
    apiGet('/v1/marketing/siswa/getAngkatan')
      .then((r) => {
        setDaftarAngkatan(r.data)
      })
  }, [])

  let user = {}
  if (localStorage.getItem('is_login')) {
    user = JSON.parse(localStorage.getItem('user'))
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home user={user} daftarAngkatan={daftarAngkatan} />}></Route>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/siswa/:status/:angkatan" element={<Siswa user={user} daftarAngkatan={daftarAngkatan} />}></Route>
          <Route path="/siswa/detail/:id" element={<SiswaDetail />}></Route>
          <Route path="/profil" element={<Profil />}></Route>

          <Route path="/sekolah" element={<Sekolah user={user} />}></Route>
          <Route path="/update" element={<RecentUpdate />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
