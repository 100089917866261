import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import moment from 'moment';

import { apiGet } from '../../api/Api'

import './Home.scss';

const Home = ({ user, daftarAngkatan }) => {
  if (!localStorage.getItem('is_login')) {
    window.location.href = '/login';
  }

  const [loading, setLoading] = useState(false);
  const [angkatan, setAngkatan] = useState('SEMUA');
  const [summary, setSummary] = useState({
    SEMUA: '-',
    BARU: '-',
    VALIDASI: '-',
    SUDAH_UJIAN: '-',
    DITERIMA: '-',
    TIDAK_DITERIMA: '-',
  });
  const [kuncianSekolah, setKuncianSekolah] = useState([]);
  const [recentUpdate, setRecentUpdate] = useState([]);
  const [linkMarketing, setLinkMarketing] = useState("");
  const [dikopi, setDikopi] = useState(false);

  useEffect(() => {
    let q = `id_marketing=${user.id_pegawai}`;

    if (angkatan && angkatan != 'SEMUA') {
      q += '&angkatan=' + angkatan
    }

    setLoading(true)
    apiGet(`/v1/marketing/siswa/get?${q}`)
      .then((r) => {
        setLoading(false)
        setSummary(r.data.summary)
      })

    apiGet(`/v1/marketing/user/getLinkMarketing?id_marketing=${user.id_pegawai}`)
      .then((r) => {
        setLinkMarketing(r.link);
      })

    apiGet(`/v1/marketing/user/getKuncian`)
      .then((r) => {
        setKuncianSekolah(r.data.reverse().splice(0, 5))
      })

    apiGet(`/v1/marketing/recent_update/get`)
      .then((r) => {
        setRecentUpdate(r.data)
      })
  }, [angkatan])

  function handleChange(e) {
    if (e.target.name == 'angkatan') {
      setAngkatan(e.target.value);
    }
  }

  function logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  function copy(link) {
    navigator.clipboard.writeText(link);
    setDikopi(true);
  }

  return (
    <div className="Home">

      <div className="topnav">
        <img src={'https://pra.pspp-integrated.com/assets/img/logo.png'} width="100" />

        <Link to="/profil">
          <img src="https://img.icons8.com/windows/512/gender-neutral-user.png" height="32" />
        </Link>
        <span onClick={() => {
          logout()
        }} style={{ cursor: 'pointer' }}>
          <img src="https://img.icons8.com/fluency-systems-regular/512/exit.png" height="30" />
        </span>
      </div>

      <h1>Aplikasi Marketing</h1>
      <hr />

      {/* <div className="form-cari">
        <input type="search" placeholder="Cari siswa..." />
        <button>
          <img src={search} width="" />
        </button>
      </div> */}

      <div>
        <h3>Jumlah perolehan siswa</h3>

        <div className="filter-bar">
          <select onChange={handleChange} name="angkatan">
            <option value="SEMUA">Semua angkatan</option>
            {
              daftarAngkatan.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))
            }
          </select>
        </div>

        <div className="card-sum grid">
          <Link to={`/siswa/semua/${angkatan}`} className="card">
            <h2>{summary.SEMUA}</h2>
            <p>Semua</p>
          </Link>
          <Link to={`/siswa/baru/${angkatan}`} className="card">
            <h2>{summary.BARU}</h2>
            <p>Daftar Baru</p>
          </Link>
          <Link to={`/siswa/validasi/${angkatan}`} className="card">
            <h2>{summary.VALIDASI}</h2>
            <p>Divalidasi</p>
          </Link>
          <Link to={`/siswa/ujian/${angkatan}`} className="card">
            <h2>{summary.SUDAH_UJIAN}</h2>
            <p>Sudah ujian</p>
          </Link>
          <Link to={`/siswa/diterima/${angkatan}`} className="card">
            <h2>{summary.DITERIMA}</h2>
            <p>Diterima</p>
          </Link>
          <Link to={`/siswa/tidak-diterima/${angkatan}`} className="card">
            <h2>{summary.TIDAK_DITERIMA}</h2>
            <p>Tidak Diterima</p>
          </Link>
        </div>

        <h3>
          Link Marketing
        </h3>
        <div className="card">
          <p style={{ textAlign: 'center' }}>
            {linkMarketing}
          </p>
          <span class="btn-copy" onClick={() => {
            copy(linkMarketing)
          }}>
            { dikopi ? 'Berhasil dicopy' : 'Copy Link'}            
          </span>
        </div>

        <h3 style={{display: 'none'}}>
          Kuncian Sekolah
          <Link to="/sekolah">Lihat semua</Link>
        </h3>
        <div className="card" style={{display: 'none'}}>
          <ul className='kuncian'>
            {
              kuncianSekolah.map((item, index) => (
                <li key={index}>
                  <div>
                    <p>{item.sekolah}</p>
                    <p style={{ fontSize: '14px', color: '#555', fontWeight: '300'}}>({item.marketing})</p>
                    <span>{item.kota_sekolah + ", " + item.provinsi_sekolah}</span>
                    <table>
                      <tr>
                        <td>Tgl Kunjungan</td>
                        <td>:</td>
                        <td>{item.tgl_kunjungan && item.tgl_kunjungan != '0000-00-00' ? moment(item.tgl_kunjungan).format('DD-MM-YYYY') : '-'}</td>
                      </tr>
                      <tr>
                        <td>Tgl Presentasi</td>
                        <td>:</td>
                        <td>{item.tgl_presentasi && item.tgl_presentasi != '0000-00-00' ? moment(item.tgl_presentasi).format('DD-MM-YYYY') : '-'}</td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <p>Tgl berlaku:</p>
                    <span>{moment(item.tgl_mulai).format('DD/MM/YYYY')} - {moment(item.tgl_berakhir).format('DD/MM/YYYY')}</span>
                  </div>
                </li>
              ))
            }

            {
              kuncianSekolah.length <= 0 && (
                <p style={{ textAlign: 'center', padding: '20px', fontSize: '14px' }}>
                  Tidak ada data
                </p>
              )
            }

          </ul>
        </div>

        <h3>
          Recent Update

          <Link to="/update">Lihat semua</Link>
        </h3>
        <div className="card">
          <ul>
            {
              recentUpdate.map((item, index) => (
                <li key={index}>
                  <p>{item.keterangan}</p>
                  <small>{moment(item.created_at).format('DD MM YYYY HH:mm')}</small>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
