import { useEffect, useState } from 'react'
import moment from 'moment'

import { apiGet } from '../../api/Api'

import back from './../../icons/back.svg';

import './../Siswa/Siswa.scss'

const RecentUpdate = () => {
    const [recentUpdate, setRecentUpdate] = useState([]);

    useEffect(() => {
        apiGet('/v1/marketing/recent_update/all')
            .then((r) => {
                setRecentUpdate(r.data);
            })
    }, []);

    return (
        <div className="Siswa">
            <div className="navbar">
                <span onClick={() => {
                    window.history.back(-1);
                }}>
                    <img src={back} height="25" />
                </span>

                <h1 id='nav-title'>Recent Update</h1>
            </div>

            <div className="card">
                <ul>
                    {
                        recentUpdate.map((item, index) => (
                            <li>
                                <div>
                                    <p style={{ fontWeight: 300, margin: 0, marginBottom: '10px' }}>{item.keterangan}</p>
                                    <small style={{ color: '#777' }}>{moment(item.created_at).format('DD-MM-YYYY HH:mm')}</small>
                                </div>
                                <div></div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default RecentUpdate;